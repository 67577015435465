import { apiDatetimeToDate } from "@warrenio/api-spec/conversion";
import type { ManagedServicesVmBody, ServiceUpdateBody } from "@warrenio/api-spec/spec.oats.gen";
import { filterFalse } from "@warrenio/utils/collections/filterFalse";
import { mustGet } from "@warrenio/utils/collections/mustGet";
import { notNull } from "@warrenio/utils/notNull";
import { Fragment, useState, type ReactNode } from "react";
import { Link } from "react-aria-components";
import { entries, first, groupBy, mapValues, sortBy } from "remeda";
import invariant from "tiny-invariant";
import { Badge } from "../../components/Badge.tsx";
import { ClipBoardTooltip } from "../../components/ClipBoardTooltip.tsx";
import ContentPane from "../../components/ContentPane.tsx";
import { NavigateAfterDelete } from "../../components/NavigateAfterDelete.tsx";
import { ResourceWithIcon } from "../../components/ResourceWithIcon.tsx";
import { Separator } from "../../components/Separator.tsx";
import { Toolbar } from "../../components/Toolbar.tsx";
import { WTabs, type WTabItem } from "../../components/WTabs.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { WModalButton, WToolButton } from "../../components/button/WToolButton.tsx";
import { LongDate } from "../../components/l10n/DateFormat.tsx";
import { DeleteModal } from "../../components/modal/DeleteModal.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { DetailsHolder, DetailsTable } from "../../components/table/DetailsTable.tsx";
import { DetailsTableBillingAccount } from "../../components/table/DetailsTableBillingAccount.tsx";
import { DetailsTableIpAddress } from "../../components/table/DetailsTableIpAddress.tsx";
import { MonthlyCostRow } from "../../components/table/DetailsTableMonthlyCost.tsx";
import { DetailsTableName } from "../../components/table/DetailsTableName.tsx";
import { DetailsTableRow } from "../../components/table/DetailsTableRow.tsx";
import { WTable, WTableBody } from "../../components/table/WTable.tsx";
import { useConfig } from "../../config.ts";
import { useDeletableResource } from "../../utils/query/useDeletableResource.tsx";
import { useQueryResultAtom, useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { getResourceById } from "../api/resourceTypeException.ts";
import { useStandardMutation, useStandardSuspenseQuery } from "../api/useStandardMutation.ts";
import { MachineSizeInfo } from "../compute/MachineSizeInfo.tsx";
import { MetricsGrid } from "../compute/MetricsGrid.tsx";
import { LocationDetail } from "../location/LocationDetail.tsx";
import * as ipQuery from "../network/ipAddress/apiOperations.ts";
import type { AssignedIpAddress } from "../network/ipAddress/assignable.ts";
import { vpcQueryAtom, type VpcWithType } from "../network/vpc/apiOperations.ts";
import { convertServiceVmToMachine } from "../pricing/resourcePricing.ts";
import { AddIpForm } from "./AddIpForm.tsx";
import { ConnectionDetailsContent } from "./ConnectionDetails.tsx";
import { ServiceDeleteModal } from "./ServiceDeleteModal.tsx";
import { allAssignedServicesQueryAtom, type ManagedServiceWithAssigned } from "./joinAssignedQuery.ts";
import { ServiceOsInfo } from "./os/ServiceOsInfo.tsx";
import { getReplicasByType } from "./serviceEntityUtils.ts";
import { serviceImagesByOsNameAtom } from "./serviceImagesQuery.ts";
import {
    deleteServiceMutation,
    deleteServiceWhitelistAddressMutation,
    getServiceWhitelistQuery,
    rebuildServiceMutation,
    startServiceMutation,
    stopServiceMutation,
    updateServiceMutation,
} from "./servicesQuery.ts";
import { statusColors, statusLabels } from "./statusLabels.tsx";

export function ServiceView({ serviceId, location }: { serviceId: string; location: string }) {
    //#region Hooks
    const { privateNetworksEnabled } = useConfig();
    const { data: vpcs } = useQueryResultAtom(vpcQueryAtom(location));
    const allImages = useSuspenseQueryAtom(serviceImagesByOsNameAtom);
    const services = useSuspenseQueryAtom(allAssignedServicesQueryAtom);

    const deleteMutation = useDeleteServiceMutation();
    const managedService = useDeletableResource(
        () => getResourceById(services, serviceId, "managed_service"),
        deleteMutation,
    );
    //#endregion

    if (managedService === undefined) {
        return <NavigateAfterDelete />;
    }

    const { resources, service } = managedService;
    const os = mustGet(allImages, service, "allImages");
    const { description, read_more_url } = os;
    const vms = resources.filter((item: ManagedServicesVmBody) => item.resource_type === "vm");
    const vm = first(vms);

    // TODO: vaata siin see loogika üle
    const vpc =
        vpcs && vm ? [...vpcs.values()].find((item: VpcWithType) => item.vm_uuids.includes(vm.resource_id)) : undefined;
    const hasNetworkTab = privateNetworksEnabled && !!vpc;

    const dbTabs: WTabItem[] =
        service === "mariadb" || service === "postgresql"
            ? [
                  {
                      id: "connection",
                      title: "Connection Details",
                      content: <ConnectionDetailsContent item={managedService} />,
                  },
                  {
                      id: "whitelist",
                      title: "IP Whitelist",
                      content: <IpWhitelistContent item={managedService} />,
                  },
              ]
            : [];

    const tabs = filterFalse<WTabItem>([
        ...dbTabs,
        { id: "metrics", title: "Metrics", content: <MetricsContent vms={vms} /> },
        hasNetworkTab && {
            id: "networking",
            title: "Networking",
            content: <NetworkingContent service={managedService} vpc={vpc} />,
        },
        {
            id: "backups",
            title: "Backups",
            content: <BackupsContent service={managedService} />,
        },
    ]);

    return (
        <>
            <ServiceToolbar
                service={managedService}
                deleteModal={<ServiceDeleteModal {...{ managedService, deleteMutation }} />}
            />
            <ServiceDetails service={managedService} vms={vms} vpc={vpc} />
            <Separator />
            <WTabs allTab autoSuspense items={tabs}>
                <ContentPane>
                    <h2 className="font-size-subtitle">Description</h2>

                    <p>
                        {description}{" "}
                        {!!read_more_url && (
                            <Link href={read_more_url} target="_blank">
                                Read more
                            </Link>
                        )}
                    </p>
                </ContentPane>
            </WTabs>
        </>
    );
}

function ServiceToolbar({ service, deleteModal }: { service: ManagedServiceWithAssigned; deleteModal: ReactNode }) {
    const startMutation = useStandardMutation(startServiceMutation);
    const stopMutation = useStandardMutation(stopServiceMutation);

    const { display_name, resources } = service;

    const hasRunningVms = !!resources
        .filter((item) => item.resource_type === "vm")
        .find((item) => item.resource_allocation.status === "running");

    async function startService() {
        await startMutation.mutateAsync({ body: service });
    }

    async function stopService() {
        await stopMutation.mutateAsync({ body: service });
    }

    return (
        <Toolbar>
            <WToolButton label="Start" icon="jp-icon-run" isDisabled={hasRunningVms} action={startService} />

            <WModal button={<WModalButton label="Stop" icon="jp-icon-stop" isDisabled={!hasRunningVms} />}>
                <WModalContent title="Stop Resource" label="Stop" modalAction={stopService}>
                    This will shut down your resource "{display_name}". If graceful shutdown does not finish in 120
                    seconds, then a forceful stop will be carried out. For stopped resources you will only be charged
                    for the storage.
                </WModalContent>
            </WModal>

            {deleteModal}
        </Toolbar>
    );
}

function useDeleteServiceMutation() {
    return useStandardMutation(deleteServiceMutation);
}

export type DeleteServiceMutation = ReturnType<typeof useDeleteServiceMutation>;

function ServiceDetails({
    service,
    vms,
    vpc,
}: {
    service: ManagedServiceWithAssigned;
    vms: ManagedServicesVmBody[];
    vpc: VpcWithType | undefined;
}) {
    //#region Hooks
    const modifyMutation = useStandardMutation(updateServiceMutation);
    //#endregion

    const { display_name, $type, properties, uuid, created_at, billing_account_id, price } = service;
    const vm = first(vms);
    const machine = vm && convertServiceVmToMachine(vm, service);

    const byStatus = groupBy(vms, (item) => item.resource_allocation.status ?? "deleted");
    const statusCount = mapValues(byStatus, (items) => items.length);

    async function onModify({ display_name, billing_account_id }: ServiceUpdateBody) {
        await modifyMutation.mutateAsync({
            body: { ...(display_name && { display_name }), ...(billing_account_id && { billing_account_id }) },
            uuid,
        });
    }

    return (
        <ContentPane>
            <h1 className="font-size-heading">{display_name}</h1>

            <DetailsHolder>
                <DetailsTable>
                    <DetailsTableName
                        value={display_name}
                        isRequired
                        onChange={async (name) => await onModify({ display_name: name })}
                    />

                    <DetailsTableRow title="Type:">
                        <ResourceWithIcon type={$type} />
                    </DetailsTableRow>

                    <DetailsTableRow title="Service:">
                        <div className="flex">
                            <ServiceOsInfo obj={service} />
                            {", "}
                            {vms.length > 1 ? `${vms.length} nodes` : "single node"}
                        </div>
                    </DetailsTableRow>

                    <DetailsTableRow title="Status:">
                        {entries(statusCount).map(([status, count]) => (
                            <Badge key={status} color={statusColors[status]}>
                                {count} {count > 1 ? "VMs" : "VM"} {statusLabels[status]}
                            </Badge>
                        ))}
                    </DetailsTableRow>

                    <ServicePublicIp item={service} />

                    {"service_ip" in properties && (
                        <DetailsTableRow title="Private IPv4:">
                            <ClipBoardTooltip isHtml>{properties.service_ip}</ClipBoardTooltip>
                        </DetailsTableRow>
                    )}

                    {vpc != null && (
                        <DetailsTableRow title="VPC network:">
                            <ResourceWithIcon type="vpc" title={vpc.name} />
                        </DetailsTableRow>
                    )}

                    {machine && (
                        <DetailsTableRow title="Size:">
                            <MachineSizeInfo vm={machine} />
                        </DetailsTableRow>
                    )}
                </DetailsTable>

                <DetailsTable>
                    <DetailsTableRow title="UUID:">
                        <ClipBoardTooltip>{uuid}</ClipBoardTooltip>
                    </DetailsTableRow>

                    <LocationDetail slug={properties.location} />

                    <DetailsTableRow title="Created:">
                        <LongDate date={created_at} />
                    </DetailsTableRow>

                    <DetailsTableRow />

                    <DetailsTableBillingAccount
                        valueKey={billing_account_id}
                        onChange={async (item) => await onModify({ billing_account_id: item.id })}
                    />

                    <MonthlyCostRow price={price} />
                </DetailsTable>
            </DetailsHolder>
        </ContentPane>
    );
}

function ServicePublicIp({ item }: { item: ManagedServiceWithAssigned }) {
    const { location, properties, assignedPublicIp } = item;

    const unAssignIpMutation = useStandardMutation(ipQuery.unassignIpAddressMutation);
    const assignIpAddressMutation = useStandardMutation(ipQuery.assignIpAddressMutation);

    async function assignIpAddress(address: AssignedIpAddress | null) {
        //TODO: mingi tala veel selle tüübiga, ei saa muidu properties.service_ip kätte
        invariant("service_ip" in properties, "service_ip is not in properties");
        const { service_ip } = properties;

        if (address) {
            await assignIpAddressMutation.mutateAsync({
                location,
                address: address.address,
                body: { private_ip: service_ip },
            });
        } else {
            await unAssignIpAddress();
        }
    }

    async function unAssignIpAddress() {
        await unAssignIpMutation.mutateAsync({ location, address: assignedPublicIp!.address });
    }

    return (
        <DetailsTableIpAddress
            value={assignedPublicIp}
            location={location}
            onChange={async (obj) => await assignIpAddress(obj)}
        />
    );
}

function IpWhitelistContent({ item }: { item: ManagedServiceWithAssigned }) {
    const { uuid } = item;

    //#region Hooks
    const whitelist = useStandardSuspenseQuery(getServiceWhitelistQuery, { uuid });
    const deleteWhitelistAddressMutation = useStandardMutation(deleteServiceWhitelistAddressMutation);
    const [isVisible, setIsVisible] = useState(false);
    //#endregion

    return (
        <ContentPane>
            <h2 className="font-size-subtitle">IP Whitelist</h2>
            <p className="color-muted pb-4">
                To restrict connections to the service you have to add at least one inbound IP to the whitelist (All
                other public and private connections will be denied). You can specify a single IPv4 address or a block
                of addresses using CIDR notation (e.g. 1.1.1.0/24)
            </p>

            <WTable
                afterTable={
                    !isVisible && (
                        <WButton
                            color="primary"
                            size="bar"
                            variant="border"
                            icon="jp-icon-add"
                            action={() => setIsVisible(!isVisible)}
                        >
                            Add IP
                        </WButton>
                    )
                }
            >
                <thead>
                    <tr>
                        <th>IP</th>
                        <th />
                    </tr>
                </thead>
                <WTableBody>
                    {whitelist.data.map((address) => (
                        <tr key={address}>
                            <td>
                                <ClipBoardTooltip>{address}</ClipBoardTooltip>
                            </td>
                            <td className="text-right">
                                <DeleteModal
                                    inTable
                                    title="Delete Whitelist IP"
                                    modalAction={async () => {
                                        await deleteWhitelistAddressMutation.mutateAsync({
                                            uuid,
                                            body: { ip_address: address },
                                        });
                                    }}
                                >
                                    This will remove the address or range from whitelist.
                                    <br />
                                    Note that empty whitelist will permit connections from any address.
                                </DeleteModal>
                            </td>
                        </tr>
                    ))}
                </WTableBody>

                {isVisible && (
                    <tfoot>
                        <tr>
                            <td colSpan={4}>
                                <AddIpForm obj={item} onClose={() => setIsVisible(false)} />
                            </td>
                        </tr>
                    </tfoot>
                )}
            </WTable>
        </ContentPane>
    );
}

function sortByCreatedAtDesc<T extends { created_at: string }>(items: T[]) {
    return sortBy(items, [(item) => apiDatetimeToDate(item.created_at).getTime(), "desc"]);
}

function MetricsContent({ vms }: { vms: ManagedServicesVmBody[] }) {
    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Metrics</h2>

            {vms.map(({ resource_allocation, resource_id, resource_location }, index) => (
                <Fragment key={resource_id}>
                    {vms.length > 1 && (
                        <h3 className={index ? "font-size-default pt-4 pb-2" : "font-size-default pb-2"}>
                            Node {index + 1}
                        </h3>
                    )}
                    <MetricsGrid
                        vm={{
                            uuid: resource_id,
                            location: notNull(resource_location, "location"),
                            status: resource_allocation.status ?? "stopped",
                        }}
                    />
                </Fragment>
            ))}
        </ContentPane>
    );
}

function NetworkingContent({ vpc, service }: { vpc: VpcWithType; service: ManagedServiceWithAssigned }) {
    const { name, subnet } = vpc;
    const { properties } = service;
    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Private Network</h2>
            <p className="color-muted pb-4">
                This service is accessible only by resources in the same VPC network using these private addresses.
            </p>

            <WTable>
                <thead>
                    <tr>
                        <th>Private IPv4</th>
                        <th>VPC Network</th>
                        <th>VPC IP Range</th>
                    </tr>
                </thead>
                <WTableBody>
                    <tr>
                        <td>
                            <ClipBoardTooltip>
                                {("service_ip" in properties ? properties.service_ip : undefined) ?? ""}
                            </ClipBoardTooltip>
                        </td>
                        <td>
                            <ResourceWithIcon type="vpc" title={name} />
                        </td>
                        <td>
                            <ClipBoardTooltip>{subnet}</ClipBoardTooltip>
                        </td>
                    </tr>
                </WTableBody>
            </WTable>
        </ContentPane>
    );
}

function BackupsTable({
    vm,
    isMultiNode,
    service_package_uuid,
}: {
    vm: ManagedServicesVmBody;
    service_package_uuid: string;
    isMultiNode: boolean;
}) {
    const mutation = useStandardMutation(rebuildServiceMutation);

    const items = sortByCreatedAtDesc(getReplicasByType(vm, "backup"));

    const latestItem = first(items);

    async function onRebuild(replica_uuid: string) {
        await mutation.mutateAsync({ location: vm.resource_location!, body: { service_package_uuid, replica_uuid } });
    }

    return (
        <>
            <DetailsTable>
                <DetailsTableRow title="Frequency:">Once a day</DetailsTableRow>

                {!!latestItem && (
                    <DetailsTableRow title="Latest snapshot:">
                        <LongDate date={latestItem.created_at} />
                    </DetailsTableRow>
                )}
            </DetailsTable>

            <WTable className="mt-3">
                <thead>
                    <tr>
                        <th>UUID</th>
                        <th>Size (GB)</th>
                        <th>Created</th>
                        {!isMultiNode && <th />}
                    </tr>
                </thead>
                <WTableBody>
                    {items.map(({ created_at, size, uuid }) => (
                        <tr key={uuid}>
                            <td>
                                <ClipBoardTooltip>{uuid}</ClipBoardTooltip>
                            </td>
                            <td>{size}</td>
                            <td>
                                <LongDate date={created_at} />
                            </td>
                            {!isMultiNode && (
                                <td className="text-right">
                                    <WModal button={<WModalButton label="Restore" inTable icon="jp-return-icon" />}>
                                        <WModalContent
                                            title="Restore Backup"
                                            label="Restore"
                                            modalAction={() => onRebuild(uuid)}
                                        >
                                            Restoring from backup will set the services state to the selected backup
                                            state.
                                            <br />
                                            {uuid}
                                        </WModalContent>
                                    </WModal>
                                </td>
                            )}
                        </tr>
                    ))}
                </WTableBody>
            </WTable>
        </>
    );
}

function BackupsContent({ service }: { service: ManagedServiceWithAssigned }) {
    const { uuid, resources } = service;
    const vms = resources.filter((item) => item.resource_type === "vm");
    const isMultiNode = vms.length > 1;

    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Backups</h2>

            <p className="color-muted pb-4">
                Automatic system-level backups are enabled. Use the backup images to restore your service. Backups will
                be stored for 7 days, meaning you will have 7 latest backups.
            </p>

            {vms.map((item, index) => (
                <Fragment key={item.resource_id}>
                    {isMultiNode && (
                        <h3 className={index ? "font-size-default pt-4 pb-2" : "font-size-default pb-2"}>
                            Node {index + 1}
                        </h3>
                    )}
                    <BackupsTable vm={item} isMultiNode={isMultiNode} service_package_uuid={uuid} />
                </Fragment>
            ))}
        </ContentPane>
    );
}
